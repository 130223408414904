import React from "react"

import { Container, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import InnerLayout from "../components/inner-layout"

import MasonryGrid from "../components/masonry-grid"

const FusedGlass = ({ data: { contentfulPage } }) => (
  <Layout>
    <SEO title="Fused Glass" />
    <InnerLayout>
      <Container>
        <Col className="text-center mx-auto mb-5" md={8}>
          <h1>Fused Glass</h1>
          <p>
            These pieces are all flat glass, decorated and fired in the kiln. A
            second firing forms the shape. Many of these pieces, though, are a
            type of hybrid starting as a free-blown plate. Embellishments are
            hand made with hot glass these are arranged and fired together.
            Finally, they are re-fired slumping them into their final shape
            which gives great depth and texture.
          </p>
        </Col>
      </Container>
      <MasonryGrid files={contentfulPage.images} />
    </InnerLayout>
  </Layout>
)

export const query = graphql`
  {
    contentfulPage(slug: { eq: "fused-glass" }) {
      name
      slug
      images {
        ... on ContentfulProductImage {
          name
          price
          image {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default FusedGlass
